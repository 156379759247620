$(document).on('turbolinks:load', function() {
  $('#copy_api_key_to_clipboard').popover();

  $('#copy_api_key_to_clipboard').click(function(e) {
    $('#api_key').select();
    document.execCommand('copy');

    $('div.popover').delay(800).fadeOut();
  });
})
